.react-datepicker {
  border: none !important;
}

.hover-white-menu-item:hover {
  background-color: white !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 1.3rem !important;
  background-color: #f0f0f0 !important;
  color: black !important;
}
