.react-datepicker__input-container input {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  border-radius: 6px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px;
  height: 53px;
  font-family: 'Work Sans Regular', sans-serif;
  background-color: #f4f6f8;
  font-weight: 500;
}

.react-datepicker__month-container {
  width: 50%;
  font-family: 'Work Sans Medium', sans-serif;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.react-datepicker__header {
  border-bottom: none;
  background-color: #fff;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 1.3rem;
  background-color: #fff;
  border: 1px solid rgb(99, 115, 129);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 1.3rem;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: #0f0f14;
  border-radius: 1.3rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 1.3rem;
  background-color: #8c8d91;
  color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: #0f0f14;
}

.react-datepicker__close-icon {
  display: flex;
  align-items: center;
  right: 7px;
}

.react-datepicker__close-icon::after {
  border: 1px solid #dfe3e8;
  background-color: transparent;
  color: #8c8d91;
  height: 20px;
  width: 20px;
  font-size: 13px;
  line-height: 1;
  content: 'x';
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 2rem;
  line-height: 2rem;
}

.react-datepicker__current-month {
  padding-top: 0rem;
  padding-bottom: 1rem;
}

.react-datepicker__navigation--previous {
  padding-top: 0.5rem;
}

.react-datepicker__navigation--next {
  padding-top: 0.5rem;
}

.react-datepicker__day--keyboard-selected:hover {
  border-radius: 1.3rem;
  background-color: #f0f0f0;
  color: #0f0f14;
}
